<template>
  <div class="etax-auth-page">
    <div class="enterprise-info">
      <p>名称：{{ enterpriseName }}</p>
      <p>税号：{{ creditCode }}</p>
      <p>法人：{{ legalPerson.xm }} {{ legalPerson.mobile }}</p>
    </div>
    <div class="auth-form">
      <van-row gutter="20">
        <van-col span="16">
          <van-field
            class="field-item"
            type="password"
            name="password"
            v-model="password"
            placeholder="请输入电子税务局密码"
          ></van-field>
        </van-col>
        <van-col span="8">
          <a href="javascript:;" class="forgot-pwd-link" @click="forgotPws"
            >忘记了密码</a
          >
        </van-col>
      </van-row>
      <van-row gutter="20" style="margin-top: 20px">
        <van-col span="16">
          <van-field
            class="field-item"
            type="number"
            name="yzm"
            v-model="yzm"
            placeholder="短信验证码"
            maxlength="6"
          ></van-field>
        </van-col>
        <van-col span="8">
          <van-button
            class="send-yzm-btn"
            type="primary"
            color="#7232dd"
            plain
            :disabled="this.yzmCountDown < 60"
            :loading="yzmBtnLoading"
            @click="sendValidationCode"
            >{{ yzmBtnText }}</van-button
          >
        </van-col>
      </van-row>
      <van-row gutter="20" style="margin-top: 20px">
        <van-col span="24" class="agreement-wrapper">
          <van-checkbox
            v-model="agreementCheck"
            icon-size="0.32rem"
            shape="square"
            checked-color="#7232dd"
            class="agreement-checkbox"
          >
          </van-checkbox>
          <span style="margin-left: 10px"
            >我同意<span
              v-for="item in agreementTips"
              :key="item.id"
              @click="toAgreementContentPage(item.id)"
              class="agreement-link"
              >《{{ item.title }}》</span
            ></span
          >
        </van-col>
      </van-row>
      <van-row gutter="20" style="margin-top: 20px">
        <van-col span="24">
          <van-button
            block
            color="#7232dd"
            class="auth-btn"
            :loading="loginBtnLoading"
            @click="authHandle"
            >税务授权登录</van-button
          >
        </van-col>
      </van-row>
    </div>
    <div class="tax-auth-tips">
      <div>
        <span style="color: red">* </span
        >该业务需要贵公司提供电子税务局的登录授权, 请注意以下声明:
      </div>
      <div>1. 该页面由“橘子税银”平台提供;</div>
      <div>2. 业务过程中需要您提供电子税务局的密码和短信验证码；</div>
      <div>
        3.
        该税务授权登录仅用于查看贵公司的申报、缴税、财务报表、税务违法等数据；
      </div>
      <div>
        4. 详细内容请查看<span
          v-for="item in agreementTips"
          :key="item.id"
          @click="toAgreementContentPage(item.id)"
          class="agreement-link"
          >《{{ item.title }}》</span
        >。
      </div>
    </div>
    <van-popup
      v-model="popup.show"
      :closeable="false"
      :close-on-click-overlay="false"
      position="top"
      round
      class="popup-message"
    >
      {{ popup.message }}
    </van-popup>
  </div>
</template>

<script>
import { Popup, Row, Col, Field, Button, Dialog, Checkbox, Toast } from "vant";
import ETaxApi from "../../api/etax";
import DashuApi from "../../api/dashu";

export default {
  name: "ETaxAuth",
  components: {
    [Popup.name]: Popup,
    [Row.name]: Row,
    [Col.name]: Col,
    [Field.name]: Field,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  props: {
    orderId: String,
    sign: String,
  },
  data() {
    return {
      enterpriseName: "",
      creditCode: "",
      productCode: "",
      agreementTips: [],
      legalPerson: {},
      hasTaxAuthorized: false,
      isHuimingdai: false,
      isTempOrder: false,
      popup: {
        show: false,
        message: "",
      },
      password: "",
      yzm: "",
      agreementCheck: false,
      yzmCountDown: 60,
      yzmCountDownTimer: null,
      yzmBtnLoading: false,
      loginBtnLoading: false,
      link: {
        hanhua:
          "http://t.hanhua.com/tong2/prod/hxdans/hxdlogin?busicode=C2019073100000047&businesstype=101300036&advert=1",
        statusPage: "/apply/status/",
      },
    };
  },
  computed: {
    yzmBtnText() {
      if (this.yzmCountDown > 59) {
        return "发送验证码";
      } else {
        return `${this.yzmCountDown}s后重发`;
      }
    },
  },
  created() {},
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (!this.orderId) {
        this.showPopup("链接无效");
      }
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        className: "loading-toast",
      });
      ETaxApi.init(this.orderId, this.sign)
        .then((res) => {
          this.enterpriseName = res.data.enterpriseName;
          this.creditCode = res.data.creditCode;
          this.agreementTips = res.data.agreementTips;
          this.legalPerson = res.data.legalPerson;
          this.productCode = res.data.productCode;
          this.hasTaxAuthorized = res.data.hasTaxAuthorized;
          this.isHuimingdai = res.data.isHuimingdai;
          this.isTempOrder = res.data.isTempOrder;
          if (this.hasTaxAuthorized) {
            this.handleAuthNextStep();
          }
        })
        .finally(() => {
          Toast.clear();
        });
    },
    showPopup(message) {
      this.popup.show = true;
      this.popup.message = message;
    },
    forgotPws() {
      Dialog.alert({
        message: "请进入电子税务局修改密码",
      });
    },
    sendValidationCode() {
      if (!this.password) {
        Dialog.alert({ message: "请输入电子税务局密码" });
        return;
      }
      this.yzmBtnLoading = true;
      ETaxApi.sendValidationCode({
        bdgxId: this.legalPerson.bdgxId,
        bsrType: this.legalPerson.bsrType,
        nsrsbh: this.creditCode,
        password: this.password,
        multiple: this.legalPerson.multiple
      })
        .then(() => {
          this.yzmCountDown--;
          this.yzmCountDownTimer = setInterval(() => {
            if (this.yzmCountDown > 1) {
              this.yzmCountDown--;
            } else {
              clearInterval(this.yzmCountDownTimer);
              this.yzmCountDown = 60;
            }
          }, 1000);
          Toast.success("短信已发送");
        })
        .finally(() => {
          this.yzmBtnLoading = false;
        });
    },
    authHandle() {
      if (!this.password || !this.yzm) {
        Dialog.alert({ message: "请输入密码和验证码" });
        return;
      }
      this.loginBtnLoading = true;
      ETaxApi.login({
        bdgxId: this.legalPerson.bdgxId,
        bsrType: this.legalPerson.bsrType,
        nsrsbh: this.creditCode,
        nsrmc: this.enterpriseName,
        password: this.password,
        smsCode: this.yzm,
        orderId: this.orderId,
        agreementCheck: this.agreementCheck,
      })
        .then((res) => {
          this.hasTaxAuthorized = res.data.hasTaxAuthorized;
          this.isHuimingdai = res.data.isHuimingdai;
          this.isTempOrder = res.data.isTempOrder;
          this.handleAuthNextStep();
          //   if (res.data.isTempOrder) {
          //     if (res.data.isHuimingdai) {
          //       this.$router.push("/apply/hmd/" + this.orderId);
          //     } else {
          //       // A5/A6进件
          //       if (this.productCode === "A5") {
          //         location.href = this.link.hanhua;
          //       } else if (this.productCode === "A6") {
          //         DashuApi.link(this.orderId).then((res) => {
          //           location.href = res.data;
          //         });
          //       } else {
          //         this.gotoStatusPage();
          //       }
          //     }
          //   } else {
          //     if (res.data.isHuimingdai && !res.data.hasTaxAuthorized) {
          //       this.$router.push("/apply/hmd/" + this.orderId);
          //     } else {
          //       this.gotoStatusPage();
          //     }
          //   }
        })
        .catch(() => {
          this.loginBtnLoading = false;
        });
    },
    gotoStatusPage() {
      this.$router.push(this.link.statusPage + this.orderId);
    },
    toAgreementContentPage(id) {
      this.$router.push("/agreement/content/" + id);
    },
    /**
     * 处理税务授权后的下一步
     */
    handleAuthNextStep() {
      if (this.isTempOrder) {
        if (this.isHuimingdai) {
          this.$router.push("/apply/hmd/" + this.orderId);
        } else {
          //   // A5/A6进件
          //   if (this.productCode === "A5") {
          //     location.href = this.link.hanhua;
          //   } else if (this.productCode === "A6") {
          //     DashuApi.link(this.orderId).then((res) => {
          //       location.href = res.data;
          //     });
          //   } else {
          //     this.gotoStatusPage();
          //   }
          if (this.productCode === "A6") {
            DashuApi.link(this.orderId).then((res) => {
              location.href = res.data;
            });
          } else {
            this.gotoStatusPage();
          }
        }
      } else {
        if (this.isHuimingdai && !this.hasTaxAuthorized) {
          this.$router.push("/apply/hmd/" + this.orderId);
        } else {
          this.gotoStatusPage();
        }
      }
    },
  },
};
</script>

<style scoped>
.etax-auth-page {
  font-size: 32px;
  line-height: 56px;
}
.enterprise-info {
  margin: 30px;
  padding: 30px;
  background-color: #eeeeee;
  border-radius: 15px;
}
.auth-form {
  margin: 30px;
}
.field-item {
  border: 1px solid #eeeeee;
}
.auth-form {
  margin-top: 50px;
  line-height: 64px;
}
.forgot-pwd-link {
  line-height: 80px;
  padding-left: 30px;
  color: #7232dd;
}
.send-yzm-btn,
.auth-btn {
  height: 80px;
}
.send-yzm-btn span,
.auth-btn span {
  line-height: 76px;
}
.agreement-wrapper {
  display: flex;
  align-items: flex-start;
}
.agreement-checkbox {
  padding: 10px;
}
.tax-auth-tips {
  max-width: 700px;
  margin: 100px auto 0 auto;
  background-color: #eee;
  padding: 20px;
}
.agreement-link {
  color: #7232dd;
}
</style>